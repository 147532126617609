import { initializeApp} from "firebase/app";

import { GoogleAuthProvider, getAuth} from "firebase/auth";

import { getFirestore } from "@firebase/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAKDV74cdD9z9AAompyaa6w_dJX1HKyCo0",
    authDomain: "jain-matrimony-6466a.firebaseapp.com",
    projectId: "jain-matrimony-6466a",
    storageBucket: "jain-matrimony-6466a.appspot.com",
    messagingSenderId: "1066496990593",
    appId: "1:1066496990593:web:e31c5b724a734125731c80",
    measurementId: "G-VMS62E28PV"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth();
const provider = new GoogleAuthProvider();
const db = getFirestore(app);

export {db, auth,provider};