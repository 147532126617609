import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';

// import reducers from '../reducers';
import { user } from "../reducers/users"
import { filterGender, filterLanguage, filterMaxAge, filterMinAge } from "../reducers/filters"
import { profiles, filterProfiles } from "../reducers/profiles"

import { persistStore, persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/es/storage';

export default function configureStore() {

	let middleware = [];
	if (process.env.NODE_ENV === 'development') {
		middleware = [...middleware, thunk, logger];
	} else {
		middleware = [...middleware, thunk];
	}

	const config = {
		key: 'root',
		storage,
		debug: true
	};

	const store = createStore(
		persistCombineReducers(config, {
			// ...reducers,
			user,
			filterGender,
			filterLanguage,
			filterMaxAge,
			filterMinAge,
			profiles,
			filterProfiles
		}),
		compose(applyMiddleware(...middleware))
		// composeEnhancers(
		// 	applyMiddleware(thunk, logger)
		// )
	);

	const persistor = persistStore(store)

	return { persistor, store };
}