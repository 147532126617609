import * as ActionTypes from './actionTypes';


export const setProfiles = (user) => ({
    type: ActionTypes.SET_PROFILES,
    payload: user
});

export const setFilterProfiles = (user) => ({
    type: ActionTypes.FILTER_PROFILES,
    payload: user
});



export const profiles = (state = null, action) => {
    switch (action.type) {
        case ActionTypes.SET_PROFILES:
            return action.payload;

        default:
            return state;
    }
};

export const filterProfiles = (state = null, action) => {
    switch (action.type) {
        case ActionTypes.FILTER_PROFILES:
            return action.payload;
            
        default:
            return state;
    }
};

