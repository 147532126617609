import React, { useEffect } from "react";
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';

import "./App.css";
import Login from "./components/Login"
import Admin from "./components/Admin"
// import Navbar from "./components/Appbar"
import Navbar from "./components/Navbar";
import Home from "./components/Home";

ReactGA.initialize("G-VMS62E28PV");

function App(props) {

	const { user } = props;

	ReactGA.send({ hitType: "pageview", page: window.location.pathname });

	// useEffect(() => { 
	// 	ReactGA.send(window.location.pathname + window.location.search);
    // }, []);

	return (
		<div class="min-h-screen flex flex-col">
			<Navbar />
			{
				!user ? (
					<Login />
				) : (
					<>
						<Switch>
							<Route path='/home' component={Home} />
							{
								user.role === "Admin" ? (
									<Route path='/admin' component={Admin} />
								) : (
									<Redirect to="/home" />
								)
							}
							<Redirect to="/home" />
						</Switch>
					</>
				)
			}
		</div>
	);
}

const mapStateToProps = state => ({
	user: state.user,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
