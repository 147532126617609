import React, {  } from 'react';
import tw from "twin.macro";
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import { setUser } from '../reducers/users';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import { db } from "../../firebase-config";
import {
  collection,
  addDoc,
} from "firebase/firestore";

const AdminContainer = tw.div`p-4 w-full flex items-center justify-center flex-grow max-w-md mx-auto text-white`;
const AdminForm = tw.div` bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col rounded border-2 border-gray-900`;

const roles = [
	{
		value: 'User',
		label: 'user',
	},
	{
		value: 'Admin',
		label: 'admin',
	},
];

export const Admin = (props) => {

	const [state, setState] = React.useState({
		email: '', role: 'User'
	});

	const usersCollectionRef = collection(db, "userDB");

	const createUser = async () => {
		await addDoc(usersCollectionRef, { email: state.email, role: state.role });
	};

	const handleChange = (event) => {
		const name = event.target.name;
		setState({
			...state,
			[name]: event.target.value,
		});
	};

	const handleSubmit = (e) => {
		// Create User
		createUser();

		// Reset State
		setState({
			email: '', role: "User"
		})
		e.preventDefault()
	}

	return (
		<AdminContainer>
			<AdminForm>
				<form noValidate onSubmit={handleSubmit}>
					<label class="block text-gray-700 text-sm font-bold mb-2" for="email">
						Email
					</label>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="email"
						label="Email Address"
						name="email"
						autoComplete="email"
						autoFocus
						value={state.email}
						onChange={handleChange}
					/>
					<label class="block text-gray-700 text-sm font-bold mb-2" for="role">
						Role
					</label>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="outlined-select-currency"
						select
						label="Select"
						name="role"
						value={state.role}
						onChange={handleChange}
						helperText="Please select the Role"
					>
						{roles.map((option) => (
							<MenuItem key={option.value} value={option.value}>
								{option.label}
							</MenuItem>
						))}
					</TextField>
					<Button type="submit" variant="contained">Submit</Button>
				</form>
			</AdminForm>
		</AdminContainer>
	);
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
	setUser: user => dispatch(setUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);