

const getUrl = (url) => {

  // let finalUrl = 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60'
  let finalUrl = 'assets/images/blank.png'

  if (url.length > 0) {

    if (url.includes("open?")) {
      finalUrl = url.replace("open?", "uc?export=view&")
    }
    else {
      const arr = url.split("/")
      const id = arr[arr.length - 2]
      finalUrl = `https://drive.google.com/uc?export=view&id=${id}`
    }
  }
  // console.log(id)
  // const finalUrl = url.replace("open?", "uc?export=view&")
  // console.log("Final Url : "+finalUrl)
  return finalUrl
}

const RenderProfile = ({ person }) => {
  let bg_color = 'bg-gray-200'
  // let bg_color_hover = 'bg-gray-300'
  let bg_color_hover = 'bg-white'

  if (person.Gender === 'Female') {
    bg_color = "bg-red-200"
    // bg_color_hover = 'bg-red-300'
  }

  return (
    <div class="flow-root p-1 w-full md:w-1/2 rounded-lg">
      <div class={`flex p-2 lg:p-4 space-x-3 rounded-lg overflow-hidden ${bg_color} hover:${bg_color_hover}`}>
        <div class="flex items-center w-1/5">
          <img class="w-16 h-16 object-cover lg:w-24 lg:h-24 rounded-lg" src={getUrl(person.Photo)} />
        </div>
        <div class="flex flex-col w-4/5 overflow-hidden">
          <div>
            <p class="text-base lg:text-xl font-semibold text-black md:truncate">
              {`${person.Name}, ${person.Age}`}
            </p>
          </div>
          <div class="flex min-w-0 justify-between">
            <div class="flex flex-col w-2/3 justify-between">
              <p class="text-sm lg:text-lg font-medium text-black-700">
                {/* {`From ${person.City}, ${person.Language}`} */}
                {`- ${person.City}, ${person.Language}`}
              </p>
              <p class="text-sm lg:text-lg text-black-700 truncate">
                {/* {`Education : ${person.Education}`} */}
                {`- ${person.Education}`}
              </p>
            </div>

            <div class="flex flex-col justify-between items-end">
              <p class="text-sm lg:text-lg truncate font-medium pb-1 px-1">
                {person.Contact}
              </p>
              <a href={person.Bio} className="text-xs lg:text-sm" target="_blank">
                <button class="rounded-full text-xs lg:text-md p-1 lg:p-2 text-black font-bold border 
                              border-black hover:border-white bg-white hover:bg-black hover:text-white">
                  Bio Data Link
                </button>
              </a>
            </div>

          </div>

        </div>
      </div>
    </div >
  )
}

export default function Card({ profiles }) {
  const headers = ["Name", "Contact", "Education", "Language", "City"]

  return (
    // <div class="p-2 md:w-md bg-white sm:p-8">
    <div class="container my-1 mx-auto px-2 md:px-12">
      <div class="flex flex-wrap -mx-1 lg:-mx-4">
        {profiles.map((person, index) => (
          <RenderProfile person={person} />
        ))}
      </div>
    </div>
  )
}

