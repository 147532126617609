import React, { useEffect } from "react";
import { connect } from 'react-redux';
import axios from "axios";
// import Table from "../Table"
import Card from "../Card"
import { setFilterProfiles, setProfiles } from "../reducers/profiles"

export function Home(props) {

    const { setProfiles, setFilterProfiles, filterProfiles } = props;
    // const [state, setState] = React.useState({
    //     gender: 'All', maxAge: 100, language: '', city: ''
    // });

    const fetchProducts = async () => {

        // const url = 'https://sheet.best/api/sheets/72829014-25cc-4662-aa4e-5031a1467608'
        // const url = 'https://sheet.best/api/sheets/c7502489-ec93-43a1-9c4c-e76bb6a2fbdc'
        const url = "https://c5mo5yaobe.execute-api.ap-south-1.amazonaws.com/test/db"

        const response = await axios
            .get(url)
            .catch((err) => console.log(err));

        if (response) {
            const profiles_db = response.data;
            // const profiles_db = response.body;
            setProfiles(profiles_db);
            setFilterProfiles(profiles_db)
            // console.log("Fetch Profiles")
        }
    };
    
    useEffect(() => {
        fetchProducts();
    }, []);

    return (
        <div>
            {
                filterProfiles && 
                filterProfiles.constructor === Array &&
                // <Table profiles={filterProfiles} />
                <Card profiles={filterProfiles} />
            }
        </div>
    )
}


const mapStateToProps = state => ({
    user: state.user,
    profiles: state.profiles,
    filterProfiles: state.filterProfiles,
});

const mapDispatchToProps = dispatch => ({
    setFilterProfiles: profiles => dispatch(setFilterProfiles(profiles)),
    setProfiles: profiles => dispatch(setProfiles(profiles))
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);