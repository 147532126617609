import * as ActionTypes from './actionTypes';


export const setUser = (user) => ({
    type: ActionTypes.SET_USER,
    payload: user
});

export const logoutUser = (user) => ({
    type: ActionTypes.LOGOUT,
    payload: user
})

export const user = (state = null, action) => {
    switch (action.type) {
        case ActionTypes.SET_USER:
            return action.payload;

        case ActionTypes.LOGOUT:
            return action.payload;

        default:
            return state;
    }
};

