import * as ActionTypes from './actionTypes';

export const setGender = (gender) => ({
    type: ActionTypes.SET_GENDER,
    payload: gender
});

export const setMinAge = (age) => ({
    type: ActionTypes.SET_MIN_AGE,
    payload: age
});

export const setMaxAge = (age) => ({
    type: ActionTypes.SET_MAX_AGE,
    payload: age
});

export const setLanguage = (language) => ({
    type: ActionTypes.SET_LANGUAGE,
    payload: language
});

export const filterGender = (state = null, action) => {
    switch (action.type) {
        case ActionTypes.SET_GENDER:
            return action.payload;

        default:
            return state;
    }
};

export const setCity = (city) => ({
    type: ActionTypes.SET_CITY,
    payload: city
});

export const filterMinAge = (state = null, action) => {
    switch (action.type) {
        case ActionTypes.SET_MIN_AGE:
            return action.payload;

        default:
            return state;
    }
};

export const filterMaxAge  = (state = null, action) => {
    switch (action.type) {
        case ActionTypes.SET_MAX_AGE:
            return action.payload;

        default:
            return state;
    }
};

export const filterLanguage = (state = null, action) => {
    switch (action.type) {
        case ActionTypes.SET_LANGUAGE:
            return action.payload;

        default:
            return state;
    }
};

export const filterCity = (state = null, action) => {
    switch (action.type) {
        case ActionTypes.SET_CITY:
            return action.payload;

        default:
            return state;
    }
};