/* This example requires Tailwind CSS v2.0+ */
import { useState } from 'react'
import { Disclosure } from '@headlessui/react'
import { LogoutIcon, MenuIcon, XIcon, FilterIcon } from '@heroicons/react/outline'
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import { logoutUser } from '../reducers/users';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { setGender, setLanguage, setMaxAge } from "../reducers/filters"
import { setFilterProfiles } from "../reducers/profiles"

const navigation = [
  { name: 'Home', href: '/home', current: true },
  { name: 'Admin', href: '/admin', current: false },
]

const genderList = [
  {
    value: 'Male',
    label: 'Male',
  },
  {
    value: 'Female',
    label: 'Female',
  },
  {
    value: 'All',
    label: 'All',
  }
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "75%",
  minWidth: 200,
  maxWidth: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex-row',
  flexWrap: 'wrap',
  m: 1
};


export function Navbar(props) {
  const { user, logoutUser, profiles, setFilterProfiles } = props;

  const [state, setState] = useState({
    gender: 'All', maxAge: 100, language: '', city: ''
  });

  const [modalState, setModalState] = useState(false)

  const signOut = () => {
    // console.log("Logout Click")
    logoutUser(null)
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const query = (profile) => {

    if (profile.Age <= state.maxAge) {
      if (state.gender === "All")
        return true

      else if (profile.Gender === state.gender)
        return true

      else
        return false
    }

    return false
  }

  const queryLanguage = (profile) => {
    if (state.language === "")
      return true

    return profile.Language.toLowerCase().indexOf(state.language.toLowerCase()) !== -1;
  }

  const queryCity = (profile) => {
    if (state.city === "")
      return true

    return profile.City.toLowerCase().indexOf(state.city.toLowerCase()) !== -1;
  }

  const handleSubmit = (e) => {

    // console.log("SUBMIT")

    // setGender(state.gender)
    // setMaxAge(state.maxAge)
    // setLanguage(state.language)

    const filterProfiles = profiles.filter(query)
      .filter(queryLanguage)
      .filter(queryCity);

    // console.log(filterProfiles)

    setFilterProfiles(filterProfiles)

    toggleModal(e)
    // Prevent Refresh
    e.preventDefault()
  }

  const toggleModal = (event) => {
    setModalState(!modalState)
    event.preventDefault()
  };

  const handleReset = (event) => {
    setFilterProfiles(profiles)
    setState({
      gender: 'All', maxAge: 100, language: '', city: ''
    })
    toggleModal(event)
    event.preventDefault()
  }

  return (
    <Disclosure as="nav" className="bg-gray-800 sticky top-0">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 
                focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center">
                  <img
                    className="h-8 w-auto"
                    // src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
                    src="assets/images/Om.png"
                    alt="Workflow"
                  />
                  <div class="hidden md:block ml-6 font-semibold text-white text-lg tracking-tight flex-wrap">
                    Shree Kahan Parivar Matrimony
                  </div>
                </div>
                {
                  user && user.role === "Admin" &&
                  <div className="hidden sm:block sm:ml-6">
                    <div className="flex space-x-4">
                      {navigation.map((item) => (
                        <div class="text-gray-300 hover:bg-gray-900 hover:text-white
                                px-3 py-2 rounded-md text-sm font-medium">
                          <NavLink to={`${item.href}`}>
                            {item.name}
                          </NavLink>
                        </div>
                      ))}
                    </div>
                  </div>
                }
              </div>
              {
                  user &&
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                    <button
                      type="button"
                      className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none 
                      focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                      onClick={toggleModal}
                    >
                      <span className="sr-only">View notifications</span>
                      <FilterIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                    <button
                      type="button"
                      className="bg-gray-800 p-1 rounded-full ml-3 text-gray-400 hover:text-white focus:outline-none 
                      focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                      onClick={signOut}
                    >
                      <span className="sr-only">View notifications</span>
                      <LogoutIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
              }
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {/* {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block px-3 py-2 rounded-md text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))} */}

              <Disclosure.Button
                key="home"
                as="a"
                href="#"
                className='text-gray-300 hover:bg-gray-700 hover:text-white
                block px-3 py-2 rounded-md text-base font-medium'
              >
                <NavLink to="/home">
                  Home
                </NavLink>
              </Disclosure.Button>

              {
                  user && user.role === "Admin" &&
                  <Disclosure.Button
                    key="admin"
                    as="a"
                    href="#"
                    className='text-gray-300 hover:bg-gray-700 hover:text-white
                    block px-3 py-2 rounded-md text-base font-medium'
                  >
                    <NavLink to="/admin">
                      Admin
                    </NavLink>
                  </Disclosure.Button>
              }
              
            </div>
          </Disclosure.Panel>

          <Modal
            open={modalState}
            onClose={toggleModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <form noValidate onSubmit={handleSubmit}>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ m: 1 }}>
                  Filters
                </Typography>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="outlined-select-currency"
                  select
                  label="Select Gender"
                  name="gender"
                  value={state.gender}
                  onChange={handleChange}
                  // helperText="Please select the gender"
                  size="small"
                  sx={{ m: 1 }}
                >
                  {genderList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="outlined-number"
                  label="Max Age"
                  type="number"
                  value={state.maxAge}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  name="maxAge"
                  onChange={handleChange}
                  sx={{ m: 1 }}
                />
                <TextField
                  id="language"
                  label="Language"
                  type="search"
                  size="small"
                  name="language"
                  value={state.language}
                  onChange={handleChange}
                  sx={{ m: 1 }}
                />
                <TextField
                  id="city"
                  label="City"
                  type="search"
                  size="small"
                  name="city"
                  value={state.city}
                  onChange={handleChange}
                  sx={{ m: 1 }}
                />
                <div>
                  <Button variant="contained" sx={{ m: 1 }} onClick={handleReset}>Reset</Button>
                  <Button type="submit" variant="contained" sx={{ m: 1 }}>Submit</Button>
                </div>
              </form>
            </Box>
          </Modal>
        </>
      )}
    </Disclosure>
  )
}

const mapStateToProps = state => ({
  user: state.user,
  profiles: state.profiles
});

const mapDispatchToProps = dispatch => ({
  logoutUser: user => dispatch(logoutUser(user)),
  setGender: gender => dispatch(setGender(gender)),
  setLanguage: language => dispatch(setLanguage(language)),
  setMaxAge: age => dispatch(setMaxAge(age)),
  setFilterProfiles: profiles => dispatch(setFilterProfiles(profiles)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
