import React, { } from 'react';
import tw from "twin.macro";
import { connect } from 'react-redux';
import { setUser } from '../reducers/users';
import Stack from '@mui/material/Stack';
import { db, auth, provider } from "../../firebase-config";
import { collection, getDocs, query, where } from "firebase/firestore";
import { signInWithPopup } from "firebase/auth";

// const LoginContainer = tw.div`p-4 flex flex-col justify-center max-w-md mx-auto bg-black text-white`;
const LoginContainer = tw.div`p-4 w-full flex items-center justify-center flex-grow max-w-md mx-auto text-white`;
const LoginForm = tw.div`bg-gray-100 shadow-md rounded-lg p-4 m-4 flex flex-col border-2 border-gray-900`;
const Image = tw.img`object-contain	rounded-lg mb-4 border-2 border-black`
const NavButton = tw.button`inline-block text-sm px-4 py-3 leading-none border-2 
    rounded-lg text-black font-bold border-black bg-gray-200
    hover:bg-gray-500 hover:text-white mt-4 lg:mt-0`;

const NavLink = tw.a`inline-block text-sm px-4 py-3 leading-none border-2 
	rounded-lg text-black font-bold text-center border-black bg-gray-200
	hover:bg-gray-500 hover:text-white mt-4 lg:mt-0`

export const Login = (props) => {

	const { setUser } = props;

	const usersCollectionRef = collection(db, "userDB");

	const executeQuery = async (email) => {
		console.log("Authorizing Email")
		const q = query(usersCollectionRef, where("email", "==", email));
		const querySnapshot = await getDocs(q);
		const queryResultsLength = Object.keys(querySnapshot.docs).length

		if (queryResultsLength) {
			const data = await getDocs(q);
			const user = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
			setUser(user[0])
		}
		else {
			alert(`Your Email ID - ${email} is not authorized. Please register. If already registered, please wait for the confirmation`);
		}

		// console.log(queryResultsLength)
	}

	const signIn = async () => {
		signInWithPopup(auth, provider)
			.then((result) => {
				const user = result.user;
				const email = user.email;
				executeQuery(email)

			}).catch((error) => {
				// console.log("LOL")
				console.log(error)
			});
	};

	const myStyle = {
		backgroundImage:
			"url('/assets/images/jambodweep-min.png')",
		height: '100%',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center center',
	};

	return (
		<div class = "flex flex-col flex-grow" style={myStyle}>
			<LoginContainer>
				<LoginForm>
					<Image src="assets/images/Songadh_Mandir-min.jpeg" alt="" />
					<Stack spacing={2}>
						<NavButton onClick={signIn}>
							LOGIN
						</NavButton>
						<NavLink href="https://forms.gle/zRYozRXLBH4GQzK88" target="_blank">
							REGISTER
						</NavLink>
					</Stack>
				</LoginForm>
			</LoginContainer>
			<div class="bg-gray-800 sticky bottom-0">
				<div className="w-full mx-auto px-2 sm:px-6 lg:px-8">
					<div className="relative flex items-center justify-between h-16">
						<div class="ml-6 font-semibold text-white text-sm tracking-tight flex-wrap">
							Contact us : jainmatrimony22@gmail.com
						</div>
					</div>
				</div>
			</div>
		</div>
	);

}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
	setUser: user => dispatch(setUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);